var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-tile',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"section-title"},[_vm._v("Info")])]},proxy:true},{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"tw-py-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex",attrs:{"data-test-id":"policy-name-section"}},[_c('label',{staticClass:"text-field-label",attrs:{"for":"policyName"}},[_vm._v("* Policy Name")]),_c('ax-text-field',{attrs:{"id":"policyName","error-messages":_vm.policyNameError,"placeholder":"Policy Name","required":"","autocomplete":"off","autofocus":!_vm.vuelidate.$anyDirty ? true : !_vm.vuelidate.$invalid && !_vm.value.name},on:{"change":function($event){return _vm.vuelidate.name.$touch()}},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}})],1),_c('div',{staticClass:"tw-flex"},[_c('label',{staticClass:"text-field-label",attrs:{"for":"patchNotes"}},[_vm._v("Notes")]),_c('ax-textarea',{attrs:{"id":"patchNotes","counter":"500","rows":"5","no-resize":"","error-messages":_vm.notesError},on:{"blur":function($event){return _vm.vuelidate.notes.$touch()}},model:{value:(_vm.value.notes),callback:function ($$v) {_vm.$set(_vm.value, "notes", $$v)},expression:"value.notes"}})],1)]),_c('v-col',{staticClass:"tw-py-0 tw-pl-6",attrs:{"cols":"12","md":"6"}},[(
            _vm.policyType === _vm.PrimaryPolicyTypes.custom ||
            _vm.policyType === _vm.PrimaryPolicyTypes.required_software
          )?_c('ax-radio-group',{staticClass:"PolicyEditor_radio-group mt-0 mb-4",attrs:{"label":"Operating System"},on:{"change":function($event){return _vm.handleOSChange($event)}},model:{value:(_vm.osFamily),callback:function ($$v) {_vm.osFamily=$$v},expression:"osFamily"}},_vm._l((_vm.osFamilyOptions),function(os){return _c('ax-radio',{key:os.value,attrs:{"value":os.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"tw-w-full",attrs:{"data-test-id":"policy-os-radio-label"}},[_vm._v(_vm._s(os.label))])]},proxy:true}],null,true)})}),1):_vm._e(),(_vm.policyType === _vm.PrimaryPolicyTypes.patch)?[_c('ax-radio-group',{staticClass:"mt-0",attrs:{"label":"Policy Status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"tw-text-sm"},[_vm._v("Policy Status")])]},proxy:true}],null,false,1305997746),model:{value:(_vm.value.configuration.auto_patch),callback:function ($$v) {_vm.$set(_vm.value.configuration, "auto_patch", $$v)},expression:"value.configuration.auto_patch"}},[_c('ax-radio',{attrs:{"label":"Active","value":true}}),_c('ax-radio',{attrs:{"label":"Inactive","value":false}})],1),_c('ax-radio-group',{attrs:{"id":"includeOptional"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"tw-flex tw-items-center tw-text-sm"},[_c('ax-os-icon',{staticClass:"tw-mr-2 align-start",attrs:{"os-family":"Windows","size":"1.5rem"}}),_vm._v(" Install Optional and Recommended Windows Updates ")],1)]},proxy:true}],null,false,835094065),model:{value:(_vm.value.configuration.include_optional),callback:function ($$v) {_vm.$set(_vm.value.configuration, "include_optional", $$v)},expression:"value.configuration.include_optional"}},[_c('ax-radio',{attrs:{"label":"Yes","value":true}}),_c('ax-radio',{attrs:{"label":"No","value":false}})],1)]:_vm._e(),(
            _vm.policyType === _vm.PrimaryPolicyTypes.patch && _vm.showRefreshCheckbox
          )?_c('ax-checkbox',{staticClass:"tw-inline-flex tw-my-6",attrs:{"hide-details":"","label":"Require device scan before policy run"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('ax-button',_vm._g({attrs:{"icon":"","inline":"","aria-label":"What does 'Require device scan before policy run' mean?"}},on),[_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v(_vm._s(_vm.mdiInformationOutline))])],1)]}}],null,false,3727722479)},[_c('span',[_vm._v("This option will force a device scan before executing the policy to make sure that the device details are up-to-date. It may take longer for the policy to execute as a result.")])])]},proxy:true}],null,false,1248563361),model:{value:(_vm.value.configuration.refresh_before_remediation),callback:function ($$v) {_vm.$set(_vm.value.configuration, "refresh_before_remediation", $$v)},expression:"value.configuration.refresh_before_remediation"}}):_vm._e()],2)],1),(_vm.isEdit)?_c('v-row',{staticClass:"tw-flex tw-justify-end"},[_c('ax-button',{attrs:{"small":"","data-test-id":"view-policy-activity-log-button","mode":"tertiary","to":{
          path: "/reports/activity-log",
          query: {
            o: _vm.orgId,
            policy_id: _vm.value.id,
            policy_type_name: _vm.value.policy_type_name,
            frompage: _vm.$route.path,
          },
        },"target":"_blank"}},[_vm._v(_vm._s(_vm.$ts('policy.viewPolicyActivityLog'))),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(_vm._s(_vm.mdiOpenInNew))])],1)],1):_vm._e()]},proxy:true}])},[_c('ax-modal',{attrs:{"value":!!_vm.clearScriptConfirm,"width":"36rem","persistent":""}},[_c('v-card',[_c('v-card-title',[_vm._v("Change Operating System")]),_c('v-card-text',[_vm._v(" Are you sure you want to switch operating systems? Your current script will be cleared. ")]),_c('v-card-actions',{staticClass:"tw-justify-end tw-px-6 tw-pb-4"},[_c('ax-button',{attrs:{"mode":"secondary"},on:{"click":_vm.cancelOSChange}},[_vm._v("Cancel")]),_c('ax-button',{attrs:{"mode":"primary"},on:{"click":_vm.handleScriptConfirmation}},[_vm._v("Continue")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }