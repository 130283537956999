import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import i18n from '@/i18n';
import { PolicyDeviceFilter } from '@/models/policy-device-filter';

export const POLICY_NAME_REQUIRED_ERROR = i18n.ts(
  'policy.validation.nameRequired',
);

const POLICY_NAME_MAX_LENGTH = 100;

export const POLICY_NAME_MAX_LENGTH_ERROR = i18n.ts(
  'policy.validation.nameMaxLength',
  { max: POLICY_NAME_MAX_LENGTH },
);

const POLICY_NOTES_MAX_LENGTH = 500;

export const POLICY_NOTES_MAX_LENGTH_ERROR = i18n.ts(
  'policy.validation.notesMaxLength',
  {
    max: POLICY_NOTES_MAX_LENGTH,
  },
);

const POLICY_CODE_EDITOR_MAX_LENGTH = 50_000;

export const PolicyInfoValidationErrorsMixin = Vue.extend({
  computed: {
    policyNameError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name } = (this as any).vuelidate;

      if (!name.$dirty) {
        return errors;
      }

      if (!name.required) {
        errors.push(POLICY_NAME_REQUIRED_ERROR);
      }
      if (!name.maxLength) {
        errors.push(POLICY_NAME_MAX_LENGTH_ERROR);
      }

      return errors;
    },
    notesError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { notes } = (this as any).vuelidate;

      if (!notes.$dirty) {
        return errors;
      }

      if (!notes.maxLength) {
        errors.push(POLICY_NOTES_MAX_LENGTH_ERROR);
      }

      return errors;
    },
  },
});

export const policyValidations = {
  name: {
    required,
    maxLength: maxLength(POLICY_NAME_MAX_LENGTH),
  },
  notes: {
    maxLength: maxLength(POLICY_NOTES_MAX_LENGTH),
  },
};

export const policyWorkletCodeEditorValidations = {
  evaluation_code: {
    maxLength: maxLength(POLICY_CODE_EDITOR_MAX_LENGTH),
    required,
  },
  remediation_code: {
    maxLength: maxLength(POLICY_CODE_EDITOR_MAX_LENGTH),
    required,
  },
};

export const policyRequiredSoftwareCodeEditorValidations = {
  installation_code: {
    maxLength: maxLength(POLICY_CODE_EDITOR_MAX_LENGTH),
  },
};

export const policyRequiredSoftwareValidation = {
  package_name: { required, maxLength: maxLength(256) },
  package_version: { required, maxLength: maxLength(128) },
};

function deviceTargetingRequired(
  this: { policyForm: { configuration: { device_filters_enabled: boolean } } },
  value,
  deviceFilter: PolicyDeviceFilter,
): boolean {
  if (!this.policyForm.configuration.device_filters_enabled) {
    return true;
  }
  if (Array.isArray(value)) {
    return !!value.length;
  }
  if (value) {
    return true;
  }
  return !deviceFilter.field && !deviceFilter.op && !deviceFilter.value?.length;
}

export const deviceTargetingValidations = {
  device_filters: {
    $each: {
      field: { deviceTargetingRequired },
      op: { deviceTargetingRequired },
      value: { deviceTargetingRequired },
    },
  },
};
